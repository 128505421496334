<!--:totalRecords="totalRecords"
:lazy="true"
--><!--:lazy="true"-->
<!--@filter="onFilter($event)"-->
<!--:filters="filters"-->
<!--@filter="onFilter($event)"-->
<!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
    <template>
      <div>
  <DataTable
      v-if="model"
    :value="listDataService"
    :lazy="false"
    class="p-datatable-responsive"
    selectionMode="single"
    data-key="id"
    @page="onPage($event)"
    :paginator="pagination"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :scrollable="(scrollHeight !== '' ? true : false) "
    :scrollHeight="scrollHeight"
    :autoLayout="true"
    filterMatchMode="startsWith"
    :first="firstRecordIndex"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >
    <Column 
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')"
      :bodyStyle="getHeaderStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')"
    >
      <template #body="slotProps">
        <!--{{c}}-->
        <span class="responsive-label">{{ getLabel(c) }}</span>
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>
          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge"  />
            <div @click.capture="click_tag_a(slotProps.data)" class="click_tag_a" v-if="c.custom == 'CutomCLickA'"  >{{slotProps.data.code}}</div>
            <div v-if="c.custom == 'SelectionText'"  v-html="callbackDataSelect( c ,slotProps.data)"></div>
          </div>
          <span v-else v-html="getData(c, slotProps.data)" :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
        </div>

      </template>
      <template v-if="c.filter" #filter>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[$CommonList.getFieldFilter(c)]"
          class="p-column-filter"
          :placeholder="getFilterPlaceHolder(c)"
          @keyup="onFilter($event,c.filter.type,c.key)"
        /><!-- :keyup="onFilter(c.filter.type,c.key)"-->
        <!--v-model="filters[getField(c)]"-->
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"
          @change="onFilter($event,c.filter.type,c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)"
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
        <Calendar 
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false" 
          :monthNavigator="true" 
          :yearNavigator="true" 
          yearRange="2000:2030" 
          dateFormat="yy-mm-dd" 
          id="date_from" 
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :showIcon="true" 
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          @date-select="select_date($event,getField(c))"
          :manualInput="false"
          />
      </template>
    </Column>
    <Column
      v-if="actions"
      headerStyle="width: 10em; text-align: center"
      bodyStyle="width: 10em; text-align: center"
    >
      <template #body="slotProps">
        <span class="responsive-label">Hành động</span>
        {{ slotProps.data.color }}
        <div>

          <Button
          v-for="(a, k) in actions.rows(slotProps.data)"
          :key="k"
          type="button"
          :icon="getIcon(a.icon)"
          :class="getClass(a.name)"
          :disabled="getDisabled(a.disabled)"
          style="margin: 2px"
          v-on:click="actionsClicked(k, a, slotProps)"
          ></Button>
        </div>
      </template>
      <template #header="slotProps">
        <Button
          v-if="actions.header"
          type="button"
          :icon="getIcon(actions.header.icon)"
          :class="getClass(actions.header.name)"
          v-on:click="actionsClicked(actions.header.name, actions.header, slotProps)"
        ></Button>
      </template>
    </Column>
    <ColumnGroup type="footer">
      <Row>
        <Column footer="Tổng:" :colspan="8" :footerStyle="{'text-align':'left'}"/>
        <Column :footer="total_package_calc" :footerStyle="{'text-align':'center'}" :colspan="1" />
        <Column :footer="total_size_m3" :footerStyle="{'text-align':'center'}" :colspan="1" />
        <Column :footer="''" :colspan="5" />
      </Row>
      <Row>
        <Column footer="Số điểm giao:" :colspan="8" :footerStyle="{'text-align':'left'}"/>
        <Column  :footerStyle="{'text-align':'center'}" :colspan="1" >
          <template #footer>
            <a class="click_trip" style="color: #C40380;" @click="click_trip" href="#"  >{{total_package_delivery_trip}}</a>
          </template>
        </Column>
        <Column :footer="''" :colspan="6" />
      </Row>
<!--      <Row>
        <Column footer="Tổng số m3:" :colspan="8" :footerStyle="{'text-align':'left'}"/>
        <Column :footer="total_size_m3" :footerStyle="{'text-align':'center'}" :colspan="1" />
        <Column :footer="''" :colspan="6" />
      </Row>-->
    </ColumnGroup>
    <template #empty>
      {{ $t('datatable.empty') }}
    </template>
    <template #loading>
      {{ $t('datatable.loading') }}
    </template>
  </DataTable>
        <Dialog
            :key="keyPopup"
            header="DS điểm giao"
            :visible.sync="showPackageDialog"
            :contentStyle="{ overflow: 'visible',maxHeight: '80vh', overflowY: 'auto' }"
            :modal="true"
        >
          <LIST_PACKAGE
              v-if="packageModel"
              :key="packageKey"
              :tableData="packagelistData"
              :model="packageModel"
              :options="options"
          ></LIST_PACKAGE>
          <template #footer>
            <Button
                label="Đóng"
                @click="showPackageDialog = false"
                icon="pi pi-check"
                class="p-button-success main-style-button"
            />
          </template>
        </Dialog>
      </div>
</template>

<script>

import LIST_PACKAGE from "./list_delivery_trip";
import moment from "moment-timezone";
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
export default {
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
    obj_id: String
  },
  components: {
    LIST_PACKAGE,
    // Popup
  },
  computed: {
    total_size_m3() {
      var total_package = 0;
      for (var i=0;i<this.listDataService.length;i++){
        var item_data =  this.listDataService[i];
        console.log("item_dataitem_data:",item_data)
        // var item_data_order = this.listDataService[i]["order"];
        var code_root = item_data.code_root
        if (code_root == item_data.order.code){
          total_package += (item_data["total_m3_root"]) ? item_data["total_m3_root"]:0
        }

      }
      return  this.$commonFuction.numberFormatCore(total_package,3,',','.');
    },
    total_package_calc() {
      var total_package = 0;
      for (var i=0;i<this.listDataService.length;i++){
        var item_data =  this.listDataService[i];
        console.log("item_dataitem_data:",item_data)
       // var item_data_order = this.listDataService[i]["order"];
        var code_root = item_data.code_root
        if (code_root == item_data.order.code){
          total_package += (item_data["total_qty"]) ? parseInt(item_data["total_qty"]):0
        }

      }
      return total_package;
    },
    total_package_delivery_trip() {
      console.log("this.listDataService11:",this.listDataService)
      var total_package = 0;
      var  map_delivery = {};
      var list_data_trip_map = {};
      for (var i=0;i<this.listDataService.length;i++){
        var item_data = this.listDataService[i];
        var order_item = item_data.order
        var doc_code = order_item?.doc_code ?? null;
        var code = order_item?.code ?? null;
        var delivery_type = item_data.delivery_type
        var address_delivery = item_data.address_delivery_root
        var key_delivery = delivery_type+address_delivery;
        var key_hash = this.$commonFuction.hashSHA256(key_delivery);
        if (this.$commonFuction.isEmpty(map_delivery[key_hash])) {
          map_delivery[key_hash] = 1;
          total_package++;
          list_data_trip_map[key_hash] = {
              text_code:code,
              text_doc_code:doc_code,
              address_delivery:address_delivery,
            address_delivery_root:address_delivery,
          }
        }else {
          list_data_trip_map[key_hash]["text_code"] = list_data_trip_map[key_hash]["text_code"]+", "+code;
          list_data_trip_map[key_hash]["text_doc_code"] = list_data_trip_map[key_hash]["text_doc_code"]+", "+doc_code;
        }
      }
      //this.list_data_trip_map = list_data_trip_map;
      return total_package;
    }
  },
  data() {
    return {
      //filters: {},
      list_data_trip_map:[],
      packagelistData:[],
      packageKey:0,
      packageModel:null,
      showPackageDialog:false,
      keyPopup: 0,
      countDropdown: 0,
      pagination: false, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '', // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService:null,
      filters: {},
      filtersCustom: [],
      lazyParams: {},
      page_transaction:null,
      firstRecordIndex:0
    };
  },
  async created() {
    console.log("modelmodelmodelmodelmodelmodelmodelmodelmodel:",this.model)
    console.log(this.tableData);
    this.test = this.tableData;
    this.listDataService =  this.tableData;
  },
  mounted () {
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    //  filtersTmp:this.filters
    };
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
        console.log("this.lazyParamsthis.lazyParams:"+this.page_transaction,this.lazyParams);
         this.firstRecordIndex = this.lazyParams.first;
        console.log("this.lazyParams:22222222",this.lazyParams);
       /* if (this.lazyParams){
          console.log("this.lazyParams.filters",this.lazyParams.filters);

        }*/
      }
    }
    this.loading = false
    this.countDropdown++;
    //this.onLazyEvent();
  },
  methods: {
    list_trip() {
      console.log("this.listDataService11:",this.listDataService)
      //var total_package = 0;
      var  map_delivery = {};
      var list_data_trip_map = {};
      var listDataService = this.$commonFuction.convertJsonObject(this.listDataService);
      for (var i=0;i<listDataService.length;i++){
        var item_data = this.$commonFuction.convertJsonObject(listDataService[i]);
        var order_item = item_data.order
        var doc_code = order_item?.doc_code ?? null;
        var code = order_item?.code ?? null;
        var delivery_type = item_data.delivery_type
        var address_delivery = item_data.address_delivery_root
        var key_delivery = delivery_type+address_delivery;
        var key_hash = this.$commonFuction.hashSHA256(key_delivery);
        if (this.$commonFuction.isEmpty(map_delivery[key_hash])) {
          map_delivery[key_hash] = 1;
          //total_package++;
          list_data_trip_map[key_hash] = {
            text_code:code,
            text_doc_code:doc_code,
            address_delivery_root:address_delivery,
            text_address_delivery:address_delivery,
          }
        }else {
          list_data_trip_map[key_hash]["text_code"] = list_data_trip_map[key_hash]["text_code"]+", "+code;
          list_data_trip_map[key_hash]["text_doc_code"] = list_data_trip_map[key_hash]["text_doc_code"]+", "+doc_code;
        }
      }
      console.log("list_data_trip_maplist_data_trip_map:",list_data_trip_map)
      var list_trip_key = []
     // var stt = 0;
      var list_data_trip_map1 = this.$commonFuction.convertJsonObject(list_data_trip_map);
      for (let key in list_data_trip_map1) {
        var item_data1 = list_data_trip_map1[key];
        ///stt++;
        //item_data1.stt = stt+"";
        list_trip_key.push(item_data1);
      }
      console.log("list_trip_keylist_trip_key:",list_trip_key)
      //this.list_data_trip_map = list_data_trip_map;
      return list_trip_key;
    },
    click_trip(event){
      event.preventDefault();
      var list_data = this.list_trip();
      console.log("list_datalist_data:",list_data)
      this.packageModel = DataServices.getModel("order_shipping_delivery_trip");
      this.packagelistData = list_data
      this.packageKey++;
      this.showPackageDialog = true;
      this.keyPopup++;
    },
    click_tag_a(data){
      console.log(data);
      window.open('/admin/list-order/view/'+data.order.id, '_blank');
      //this.$router.push({ path: '/admin/list-order/view/'+data.id ,query: { key_tmp: (new Date()).getTime() } })
    },
    callbackDataSelect(c,data){
      var text = "";
      switch(c.key) {
        case 'current_order_status_id':
          text =  data?.order?.order_status?.name ?? '';
          break;
        case 'unit_name':
          text  = (!this.$commonFuction.isEmpty(data.unit)) ? data.unit.name:'';
          break;
        case 'materials_code':
          text  = (!this.$commonFuction.isEmpty(data.material)) ? data.material.code:'';
          break;
        case 'warehouse_code':
          text  = (!this.$commonFuction.isEmpty(data.warehouse)) ? data.warehouse.code:'';
          break;
        default:
      }
      return text;
    },
    select_date(event,col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if(col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange)  f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      //console.log("colcolcol:",col);
      //console.log("rowrowrowrow:",row);
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm:ss DD/MM/yyyy");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm - DD/MM/yyyy");
      } else if(col.type == "Hour" && row[col.key]){
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter( a => a.value  === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      } 
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      return this.$CoreService.getFilterCommonPlaceHolder(col);
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams:this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
   async dataList(lazyParams) {
      console.log('lazyParams');
      console.log('lazyParams:',lazyParams);
     var offset = lazyParams.first;
     var limit = lazyParams.rows;
     console.log("this.model.tableName:",this.model.tableName)
     var gqLQueryList = DataServices.getList(this.model.tableName);
     var where = {order_id:{_eq: this.obj_id}};
//console.log("wherewherewherewhere:",where)
     var filters = lazyParams.filters;
     if (filters){
       for (const [key, value] of Object.entries(filters)) {
         if (!this.$commonFuction.isEmpty(value.value)) {
           if (value.type_input == 'input') {
             if (key == 'fullname'){
               where['plain_fullname'] = {_like: "%"+this.$commonFuction.SkipVN(value.value)+"%"};
             }else {
               where[key] = {_like: "%" + value.value + "%"};
             }
           } else if (value.type_input == 'select') {
             if (key == 'name_group_filter') {
               where['group_id'] = {_eq: value.value};
             }
           }
         }
       }
     }
     var resData = await this.$apollo.mutate({
       mutation: gqLQueryList,
       variables: {
         where_key:where ,
         offset:offset,
         limit:limit,
         orderBy:{created_at: 'desc'}
       }
     });
     var listData = resData.data[this.model.tableName];

     var gqLQueryCount = DataServices.countData(this.model.tableName);
     var countRes = await this.$apollo.mutate({
       mutation: gqLQueryCount,
       variables: {
         where_key:where
       }
     });
     var total = countRes.data[this.model.tableName+'_aggregate']['aggregate']['count'];

     var dataRes = {
       listData: listData,
       total: total,
     };
     console.log('dataRes:',dataRes);
     return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      console.log('this.lazyParams:', this.lazyParams)
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then(data => {
        console.log("datadata:", data)
        //var count = 0;
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first;
        for (var i = 0; i < data.listData.length; i++) {
          stt++;
          data.listData[i].stt = stt;
          /*if(data.listData[i].rel_object_group_uses !== undefined && data.listData[i].rel_object_group_uses !== null){
              data.listData[i].name_group_filter = data.listData[i].rel_object_group_uses.group_id;
          }else{
            data.listData[i].name_group_filter = 0;
          }*/
          var item_data = data.listData[i]
          data.listData[i].unit_name  = (!this.$commonFuction.isEmpty(item_data.unit)) ? item_data.unit.name:'';
          console.log("data.listData[i].unit_name:",data.listData[i].unit_name)
          data.listData[i].materials_code  = (!this.$commonFuction.isEmpty(item_data.material)) ? item_data.material.code:'';
          data.listData[i].warehouse_code  = (!this.$commonFuction.isEmpty(item_data.warehouse)) ? item_data.warehouse.code:'';
          var export_qty  = (!this.$commonFuction.isEmpty(item_data.export_qty)) ? parseFloat(item_data.export_qty):0;
          var import_qty  = (!this.$commonFuction.isEmpty(item_data.import_qty)) ? parseFloat(item_data.import_qty):0;
          data.listData[i].qty = (export_qty >0) ? export_qty:import_qty;
        }
        that.listDataService = data.listData;
        that.totalRecords = data.total;
        that.loading = false;
      });
    },
    onPage(event) {
      console.log("eventeventeventevent",event);
      this.lazyParams = event;
      //this.onLazyEvent();
    },
    onFilter(event,type_input,key) {
      //this.loading = true;
      this.tableData = this.test;
      console.log('this.test',this.test);
      console.log('this.test',this.tableData);
      console.log('tableData',this.whereKeyList);
      console.log('filters:',this.filters);
      this.filtersCustom[key] = {
        type_input:type_input,
        value:this.filters[key],
      };
      console.log('this.filtersCustom:',this.filtersCustom);
      this.lazyParams.first = 0;
      //this.onLazyEvent()
      /*setTimeout(() => {
        this.customers = this.datasource.slice(event.first, event.first + event.rows);
        this.loading = false;
      }, 500);*/
    },
  }
};
</script>
<style lang="scss">
.p-paginator-bottom{padding: 0 !important;}
.click_tag_a{
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 16px/21px Segoe UI;
  letter-spacing: 0px;
  color: #C40380;
  cursor: pointer;
}
</style>