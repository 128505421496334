<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="p-card-field">
        <label class="p-col-fixed txt-right mb-2">Đơn hàng <span style="color: red">*</span></label>
        <div class="p-col div_order">
          <InputText maxlength="50"  v-model="code_order" type="text" class="p-col-width style-add-order input_order" placeholder="Nhập " />
          <div class="div_button">
          <Button
            type="button"
            icon="pi pi-plus"
            class="p-button-success mb-lg-0 mb-2 -2 ml-3 p-2 rounded button_margin"
            @click="add_order_list"
            severity="success"
            style="height: 45px; width: 45px;"
          >          
          </Button>
          <BarCodeScan class="button_margin" @success_scan="success_scan" />
          </div>
        </div>
      </div>
      <div class="p-card-field style_table_ship">
        <input class="input_tmp_validator" id="validator_table_order">
      <BaseDatatable
          v-if="dataModel"
          :key="componentKey"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :actions="getRowActions()"
          :whereKeyList="whereKeyList"
          v-on:action-click="onActionClicked"
      ></BaseDatatable>
      </div>
      <div class="p-card-field">
        <label class="p-col-fixed txt-right ">Số thứ tự chuyến giao <span style="color: red">*</span></label>
        <div class="p-col">
<!--          <InputNumber
              class="p-col-width text-box input-text-box border_input"
              v-model="order_shipping.stt"
              min="0"
              max="99"
              id="stt"
              placeholder="Nhập "
              @keydown="limitInput"
          />-->
          <InputText maxlength="2" required v-model="order_shipping.stt"  id="stt" type="text" class="p-col-width text-box input-text-box border_input" placeholder="Nhập " @keyup="limitInput"/>
        </div>
      </div>
        <div class="p-card-field">
            <label class="p-col-fixed txt-right">Phương tiện giao hàng <span style="color: red">*</span></label>
          <div class="p-col">
            <v-select :clearable="false"  class="input_width text-box " id= "vehicle" :filterable="false" placeholder="Chọn" v-model="selectedVehicle" :options="vehicle" :reduce="vehicle_i => vehicle_i.id" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
            <input class="input_tmp_validator" id="vali_vehicle">
          </div>
          </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Nhân viên giao <span style="color: red">*</span></label>
          <div class="p-col">
            <v-select :clearable="false"  id= "staff" class="input_width text-box" :filterable="false" placeholder="Chọn" v-model="selectedStaffDelivery" :options="StaffDelivery" :reduce="StaffDelivery_i => StaffDelivery_i.id" label="fullname"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
            <input class="input_tmp_validator" id="vali_staff">
          </div>

        </div>


        <div class="p-card-field">
            <label class="p-col-fixed txt-right ">Ghi chú điều phối</label>
            <div class="p-col">
              <Textarea maxlength="1000" class="p-col-width text-box input-text-box border_input" placeholder="Nhập ghi chú"  v-model="order_shipping.note" :autoResize="true"  cols="30"  />
<!--              <InputText maxlength="50" v-model="order_shipping.note"  id="note" type="text" class="p-col-width text-box input-text-box border_input" placeholder="Nhập ghi chú" />
           --> </div>
          </div>
      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button

              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-warning callback-btn callback-btn-cus back_class"
          />
          <div v-if="$route.name != 'viewOrdershipping'">
          <Button
              v-if="mode == 'edit'"
              :label="'Xác nhận cập nhật điều phối'"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="'Xác nhận cập nhật điều phối'"
              @click="saveData()"
              class="main-style-button"
          />
          </div>
        </template>
      </Toolbar>
      <Dialog
          header="Xóa"
          :visible.sync="showDeleteDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
      >
        Bạn có chắc muốn xóa <strong></strong>
        <template
            #footer
        >
          <Button label="Xóa" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
          <Button
              label="Hủy"
              @click="showDeleteDialog = false"
              icon="pi pi-times"
              class="p-button-warning"
          />
        </template>
      </Dialog>
    </div>
  </form>
<!--  <span class="responsive-label">{{ getLabel(c) }}</span>-->
</template>
<script>
import DataServices from '@/core/DataServices'
// import gql from "graphql-tag";
import BarCodeScan from "./index_barcode.vue"
import BaseDatatable from './list_order_ship_item'
import ApiRepository from "@/core/ApiRepository";
import AuthenticationApp from "@/core/AuthenticationApp";
export default {
  components: {
    BaseDatatable,
    BarCodeScan,
    // Popup
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      showDeleteDialog:false,
      list_map:{},
      code_order:null,
      filterOptions: {},
      options: [],
      listData: [],
      modelName: '',
      dataModel: null,
      componentKey: 0,
      whereKeyList:{
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: {_eq: false}
        }
      },
      checked: true,
      order_shipping:{
        stt: null,
        note: null
      },
      selectedVehicle: null,
      selectedStaffDelivery: null,
      selectedDistrict: null,
      selectedType: null,
      vehicle: [],
      StaffDelivery: [],
      district: [],
      type: [],
      code: '',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role:[],
      saving:false,
      map_order_data:{},
      addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}
    }
  },
  async mounted() {

    // đổ dữ liệu khi edit
    // if (this.model !== undefined && Object.keys(this.model).length > 0) {
// lấy data đổ vào input
    this.area = this.$commonFuction.convertJsonObject(this.model);
    this.checked = this.area.active
    console.log("group channel",this.area)



    // đổ danh sach  data ra khi add hoac edit select data
    var list_vehicle = DataServices.getList('vehicle');
    var resdata_vehicle = await this.$apollo.mutate({
      mutation: list_vehicle,
      variables: {
        orderBy: {created_at: 'desc'}
      }
    });
    var vehicle = resdata_vehicle.data['vehicle'];
    this.vehicle = vehicle;

    console.log("vehicle",this.vehicle)


    // đổ danh sach  data ra khi add hoac edit select data
    var list_staff_delivery = DataServices.getList('users');

    //lấy loại nhân viên giao hàng
    var where_staff = {
      active : {_eq: true},
      deleted : {_eq: false},
      "department_users": {
        department: {type: {_eq: "4"}}
      }
    }
    var listDisplayCompany = AuthenticationApp.getListCompany();
    if (listDisplayCompany != null) {
      //order_shipping(where: {order_shipping_items: {order: {company_id: {_in: ""}}}})
      where_staff.users_companies = {company_id: {_in: listDisplayCompany}};
    }
    var resdata_staff_delivery = await this.$apollo.mutate({
      mutation: list_staff_delivery,
      variables: {
        where_key : where_staff,
        orderBy: {created_at: 'desc'}
      }
    });
    var staff_delivery = resdata_staff_delivery.data['users'];


      this.StaffDelivery = staff_delivery;

      console.log("satfff ", this.StaffDelivery)



    // đổ danh sach  data ra khi add hoac edit select data
    var list_district = DataServices.getList('district');

    var resdata_district = await this.$apollo.mutate({
      mutation: list_district,
      variables: {
        orderBy: {created_at: 'desc'}
      }
    });
    var district = resdata_district.data['district'];
    this.district = district;



    // đổ danh sach  data ra khi add hoac edit select data
    var list_type = DataServices.getList('area_type');


    console.log("list_type_data")
    var resdata_type = await this.$apollo.mutate({
      mutation: list_type,
      variables: {
        orderBy: {created_at: 'desc'}
      }
    });
    console.log("resss",resdata_type)
    var type = resdata_type.data['area_type'];
    this.type = type;
    console.log("STATttE",this.type)



    // đổ data đã chọn  ra khi edit
    console.log(this.mode,"modeeeeee")
    if (this.mode == 'edit') {
      console.log('this.area:',this.area)
      var order_deliveries = (this.area.order_deliveries) ? this.area.order_deliveries:'';
      this.order_shipping.stt = order_deliveries;

      var vehicle_id = (this.area.vehicle_id) ? this.area.vehicle_id:null;
      this.selectedVehicle = vehicle_id;

      var uid_ = (this.area.uid) ? this.area.uid:null;
      this.selectedStaffDelivery = uid_;

      this.order_shipping.note = (this.area.note) ? this.area.note:'';

      /*var selected_type = (this.area.area_type) ? this.area.area_type:[];
      console.log("type",selected_type)
      this.selectedType = selected_type.name;*/
    }
    this.modelName = 'order_shipping_item';
    // if (list_code.length>0){
    var list_data   = []
    var list_order_group_parcel_id = [];
    if (this.mode == 'edit') {
      console.log("this.model.idthis.model.id:",this.model.id)
      if (this.model.id) {
        let variables = {
          'where_key': {
            'order_shipping_id': {'_eq': this.model.id}
          },
          orderBy: {ordinal: "asc"}
        }
        var list_order_shipping = DataServices.getList('order_shipping_item');
        var resdata_state = await this.$apollo.mutate({
          mutation: list_order_shipping,
          variables: variables
        });
        list_data = resdata_state.data['order_shipping_item'];
        for (var k1 = 0; k1 < list_data.length; k1++) {
          var key_data_ = (new Date()).getTime() + this.$commonFuction.makeId(8)
          list_data[k1]["key_data"] = key_data_
          this.map_order_data[key_data_] = list_data[k1]
          list_data[k1]["order"]["total_package"] = list_data[k1]["total_package"];
          var order_group_parcel_id_key = list_data[k1]["order_group_parcel_id"]
          if (!this.$commonFuction.isEmpty(order_group_parcel_id_key)) {
            list_order_group_parcel_id.push(order_group_parcel_id_key)
          }
        }
      }
      console.log("list_datalist_datalist_data:",list_data)
    }else {
      if (!this.$commonFuction.isEmpty(this.$route.query)){
        if (!this.$commonFuction.isEmpty(this.$route.query.status_transaction)){
          this.status_transaction = this.$route.query.status_transaction;
          var data_status = (localStorage.getItem(this.status_transaction)) ? JSON.parse(localStorage.getItem(this.status_transaction)) : {};
        //  var type_status = data_status.type_status
        //  var title_page = data_status.title_page
          var list_code = data_status.list_code
        //  var button_name = data_status.button_name
          for (var k=0;k<list_code.length;k++){
            var list_code_ = []
            list_code_.push(list_code[k])
            var listdata_convert =await this.load_list_order_by_code(list_code_,'order')
            var item_order = (listdata_convert.length > 0) ? listdata_convert[0]:null;
            if (item_order) {
              var where_history_status_order = {order_id: {_eq: item_order.id}};
              var history_status_order = await this.$CoreService.getListData('history_status_order', where_history_status_order, null, null);
              item_order["history_status_orders"] = history_status_order;
            }
            console.log("item_orderitem_orderitem_order:",item_order)
            var key_data = (new Date()).getTime()+this.$commonFuction.makeId(8)
            var object_data = {
              delivery_type:item_order?.suggest_delivery_type,
              address_delivery_root:item_order?.suggest_address_customer,
              key_data:key_data,
              order:item_order,
            }
            var order_group_parcel_id_key1 = item_order["order_group_parcel_id"]
            if (!this.$commonFuction.isEmpty(order_group_parcel_id_key1)) {
              object_data["order_group_parcel_id"] = order_group_parcel_id_key1;
              list_order_group_parcel_id.push(order_group_parcel_id_key1)
            }
            this.map_order_data[key_data] = object_data
            list_data.push(object_data)



          }

        }
      }
    }
  console.log("list_order_group_parcel_idlist_order_group_parcel_id:",list_order_group_parcel_id)
    if (list_order_group_parcel_id.length > 0){
      var data_order_parcel =await this.$CoreService.getOrderParcel(list_order_group_parcel_id);
      var map_order_group_parcel = data_order_parcel?.map_order_group_parcel
      for (var k3 = 0; k3 < list_data.length; k3++) {
        var itemk3 = list_data[k3];
        var order_group_parcel_id2 = itemk3["order_group_parcel_id"]
        if (!this.$commonFuction.isEmpty(order_group_parcel_id2)) {
          var item_map = map_order_group_parcel[order_group_parcel_id2]
          var text_m3 = (item_map.arr_text.length > 0) ? item_map.arr_text.join("; "):"";
          var total_size = item_map?.total_size ?? 0;
          var code_root = item_map?.code_root ?? '';
          var total_qty = item_map?.total_qty ?? 0;
          list_data[k3]["total_m3_root"] = total_size;
          list_data[k3]["total_m3"] = this.$commonFuction.numberFormatCore(total_size,3,',','.');
          list_data[k3]["detail_package"] = text_m3;
          list_data[k3]["code_root"] = code_root;
          list_data[k3]["total_qty"] = total_qty;
        }
      }
    }

    this.listData = list_data
    //}
    console.log("asdasdasd",this.listData)
    this.dataModel = DataServices.getModel(this.modelName);
    console.log("this.dataModelthis.dataModel",this.dataModel)
    this.dataModel.processData(list_data)
    //this.title = '';
    this.callbackOptions();
    this.componentKey++;
    this.map_list_id();
  },
  methods: {
    limitInput() {
      if (this.order_shipping.stt) {
        var text_key = this.order_shipping.stt
        ///console.log("limitInput:",text_key.toString().length)
        text_key = text_key.replace(/[^\d]/g, '');
        /*if (text_key.length > 2) {
        text_key = text_key.slice(0, 2); // Cắt bớt các ký tự vượt quá 2
      }*/
        this.order_shipping.stt = text_key
      }
    },
    success_scan(data){
      this.code_order = data
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    async deleteListOrder(order_id) {
      console.log("order_idorder_id:",order_id)
      var list_data = this.listData;
      var list_convert = [];
      for (var i=0;i<list_data.length;i++){
        var item_data = list_data[i];
        var id_order_check = item_data.key_data;
        if (order_id != id_order_check){
          list_convert.push(item_data)
        }
      }
      this.listData = list_convert
    },
    async deleteData() {
      this.showDeleteDialog = false;
      var item_order = this.map_order_data[this.deleteId]
      console.log("item_orderitem_order:",item_order)
      console.log("this.map_order_data:",this.map_order_data)
      if (!this.$commonFuction.isEmpty(item_order)){
        var order_group_parcel_id = item_order.order.order_group_parcel_id;
        console.log("item_order111:",item_order)
        console.log("order_group_parcel_id:",order_group_parcel_id)
        if (!this.$commonFuction.isEmpty(order_group_parcel_id)) {
          console.log("this.listData:",this.listData.length)
            var list_key_data_delete = [];
            for (var i=0;i<this.listData.length;i++){
              var item_data = this.listData[i];
              console.log("vvvvvvvvvvvvv:")
              if (!this.$commonFuction.isEmpty(item_data)) {
                console.log("33333:")
                var order_data = item_data.order;
                if (!this.$commonFuction.isEmpty(order_data.order_group_parcel_id)) {
                  var order_group_parcel_id_check = order_data.order_group_parcel_id;
                  console.log("order_group_parcel_id_check:", order_group_parcel_id_check)
                  if (order_group_parcel_id_check == order_group_parcel_id) {
                    console.log("ddddddddddddddddddddddd")
                    list_key_data_delete.push(item_data.key_data)
                  }
                }
              }
            }
            for (var j=0;j<list_key_data_delete.length;j++){
              await this.deleteListOrder(list_key_data_delete[j])
              this.componentKey++;
              this.map_list_id();
            }
        }else {
          //this.map_order_data[key_data]
          await this.deleteListOrder(this.deleteId)
          this.componentKey++;
          this.map_list_id();
        }
      }

      },
    async callbackOptions() {
      console.log("tettststststst:ssssss")
      var fields = this.dataModel.getDisplayFields();
      console.log("tettststststst:",fields)
      var attributes = [];
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          //var where_status = {}
          /*if( element['name']== "rel_status"){
            where_status =  {
              type: {'_eq': 'ORDER'}
            }
          }*/
          var where_ = {}
          if( element['name']== "name_group_filter"){
            where_ =  {
              active: {_eq: true},
              deleted: {_eq: false},
            }
            element.models.tableName = 'group'
          }
          console.log("element.models:",element.models);
          var {data} = await this.$apollo.query({
            query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
            variables: {where_key : where_}
          })
          if (data) {
            var result = data[Object.keys(data)];
            if (result) {
              for (let index = 0; index < result.length; index++) {
                const ele = result[index];
                optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
              }
            }
          }
          console.log("optionsDataoptionsData:",optionsData)
          this.options[element.key] = optionsData;
        }
        //this.componentKey++;
      }

    },
    async load_list_order_by_code(list_code,modelName) {
      //this.obj_id
      var gqLQueryListOrderDocs = DataServices.getList(modelName);
      var where_user_docs = {
        code: {_in: list_code},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['order'];
      var listdata_convert = []
      //var count_stt = 0;
      for (var i=0;i<listData.length;i++){
        var item_order = listData[i]

        /*var order_shipping_code = '';
        var name_warehouse = '';
        var detail_text = '';
        if (this.modelName=='order_custom_warehouse'){
          //lấy mã chuyến
          if (!this.$commonFuction.isEmpty(item_order.obj_order_shipping_items)){
            order_shipping_code = (!this.$commonFuction.isEmpty(item_order.obj_order_shipping_items.order_shipping)) ? item_order.obj_order_shipping_items.order_shipping.code:'';
          }
          //lấy tên kho
          if (!this.$commonFuction.isEmpty(item_order.obj_order_item)){

            name_warehouse = (!this.$commonFuction.isEmpty(item_order.obj_order_item.warehouseByWarehouseId)) ? item_order.obj_order_item.warehouseByWarehouseId.name:'';
          }
          //chi tiet
        }
        item_order["order_shipping_code"] = order_shipping_code;
        item_order["name_warehouse"] = name_warehouse;
        console.log('name_warehousename_warehouse:',name_warehouse)
        item_order["detail_text"] = detail_text;*/
        listdata_convert.push(item_order)
      }
      return listdata_convert;
    },
    async validateOrderCode(){
      var listCompanyDisplay = AuthenticationApp.getListCompany();
      var where_obj = {
        code: {_eq: this.code_order}
      }
      if (listCompanyDisplay != null){
        where_obj.company_id = {_in: listCompanyDisplay}
      }
      var obj_data = await this.$CoreService.getObjectData('order', where_obj);
      if (this.$commonFuction.isEmpty(obj_data)) {
        alert("Đơn hàng không hợp lệ");
        return true
      } else {
        // this.order_id_pakage= obj_data.id
        return false
      }
    },  
    async add_order_list() {
      if (await this.validateOrderCode()){
        this.code_order = ''
        return;
      }
      var order_code = this.code_order
      this.$commonFuction.is_loading(true);
      var  data_check_res = await  this.$CoreService.check_status_order([order_code], 2,this.$constants.STATUS_ORDER.COORDINATION_COMPLETED)
      console.log("data_check_res:",data_check_res)
      this.$commonFuction.is_loading(false);
      if (data_check_res) {
        alert(data_check_res["text_err"]);
        this.code_order = ''
        return false
        /*
        list_err = data_check_res["data"]
        if (list_err.length > 0) {
          alert("Các đơn hàng không thể thay đổi trạng thái này:" + list_err.join(', '));
          return false
        }*/
      }

      var item_order =await this.add_order_code(order_code)
      console.log("item_order:",item_order)
      if (!this.$commonFuction.isEmpty(item_order) && item_order != false){
          var order_group_parcel_id = item_order.order_group_parcel_id;
        if (!this.$commonFuction.isEmpty(order_group_parcel_id)) {
          var list_order =await this.get_order_by_group_parcel_id(order_group_parcel_id);
          for (var i=0;i<list_order.length;i++){
            var item_order_c = list_order[i];
            if (item_order_c.id !=item_order.id){
              //add những đơn hàng ghép chung kiện vào
              await this.add_order_code(item_order_c.code)
            }
          }
        }
      }
      console.log("itasdasda:",item_order)
    },
    async get_order_by_group_parcel_id(order_group_parcel_id) {
      var gqLQueryListData = DataServices.getList('order');
      var where_data = {
        order_group_parcel_id: {_eq: order_group_parcel_id},
        deleted: {_eq: false},
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_data.data['order'];
      return listData;

    },
    async add_order_code(order_code) {
      if (!this.$commonFuction.isEmpty(order_code)){
        var list_code = [];
        list_code.push(order_code);
        var listdata_convert =await this.load_list_order_by_code(list_code,'order')
        var item_order = (listdata_convert.length > 0) ? listdata_convert[0]:null;
        if (!item_order){
          alert("Đơn hàng không tồn tại!")
          return false;
        }
        var order_id = item_order.id
        if (!this.$commonFuction.isEmpty(this.list_map[order_id])){
          alert("Đơn hàng đã tồn tại!")
          return false;
        }
        var where_history_status_order = {order_id: {_eq: order_id}};
        var history_status_order = await  this.$CoreService.getListData('history_status_order',where_history_status_order,null,null);
        item_order["history_status_orders"] = history_status_order;
        var list_order_group_parcel_id = [];
        var order_group_parcel_id_key1 = item_order["order_group_parcel_id"]

        var listData = this.listData;
        var key_data = (new Date()).getTime()+this.$commonFuction.makeId(8)
        var object_data = {
          delivery_type:item_order.suggest_delivery_type,
          address_delivery_root:item_order.suggest_address_customer,
          key_data:key_data,
          order:item_order,
        }
        if (!this.$commonFuction.isEmpty(order_group_parcel_id_key1)) {
          list_order_group_parcel_id.push(order_group_parcel_id_key1)
          var data_order_parcel =await this.$CoreService.getOrderParcel(list_order_group_parcel_id);
          var map_order_group_parcel = data_order_parcel?.map_order_group_parcel
          console.log("map_order_group_parcel:",map_order_group_parcel)
          var item_map = map_order_group_parcel[order_group_parcel_id_key1]
          var text_m3 = (item_map.arr_text.length > 0) ? item_map.arr_text.join("; "):"";
          var total_size = item_map?.total_size ?? 0;
          var total_qty = item_map?.total_qty ?? 0;
          var code_root = item_map?.code_root ?? '';
          object_data["total_m3_root"] = total_size;
          object_data["total_m3"] = this.$commonFuction.numberFormatCore(total_size,3,',','.');
          object_data["detail_package"] = text_m3;
          object_data["code_root"] = code_root;
          object_data["total_qty"] = total_qty;
        }
        this.map_order_data[key_data] = object_data
        console.log("object_dataobject_data:",object_data)
        listData.push(object_data);
        this.listData = listData;
        this.dataModel = DataServices.getModel(this.modelName);
        this.dataModel.processData(listData)
        this.callbackOptions();
        this.componentKey++;
        this.map_list_id();
        this.code_order = '';
        return item_order;
      }
    },
    async map_list_id() {
      var list_map = {};
      for (var i=0;i<this.listData.length;i++){
        var id = this.listData[i].order.id
        list_map[id]= this.listData[i]
      }
     /* for (var j=0;j<this.listData1.length;j++){
        var id1 = this.listData1[j].id
        list_map[id1]= this.listData1[j]
      }*/
      this.list_map = list_map
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
            console.log("this.deleteId:",this.deleteId)
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    getRowActions() {
      var actions = {}
      var that = this;
      /*this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD).then( (res)  => {
       if (res){
         actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
       }
      })*/
      /* if (this.role.add) {
         actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
       }*/
      //if ((await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD))){

      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        /* if (that.role.edit) {
           buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
         }*/
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        // if (that.role.delete) {
        buttons.delete = {name: 'delete', icon: 'trash', key: 'key_data'}
        // }

        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return  actions
    },
     async handleSelectChange() {
    console.log("select",this.selectedCountry);

    },
    async active() {
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};

      let stt_add = document.getElementById("stt");
      if (this.order_shipping.stt == undefined || this.order_shipping.stt == null || this.order_shipping.stt == ''){
        stt_add.setCustomValidity("Số thứ tự chuyến giao không được để trống!");
      }else {
        stt_add.setCustomValidity("");
      }

      let validator_table_order = document.getElementById("validator_table_order");
      if (this.listData.length<1){
        validator_table_order.setCustomValidity("Vui lòng thêm đơn hàng");
      }else {
        validator_table_order.setCustomValidity("");
      }


      let vehicle_add = document.getElementById("vali_vehicle");
      if (this.$commonFuction.isEmptyObject(this.selectedVehicle)){
        vehicle_add.setCustomValidity("Phương tiện  không được để trống!");
      }else {
        vehicle_add.setCustomValidity("");
      }
      let staff_add = document.getElementById("vali_staff");
      if (this.$commonFuction.isEmptyObject(this.selectedStaffDelivery)){
        staff_add.setCustomValidity("Nhân viên giao không được để trống!");
      }else {
        staff_add.setCustomValidity("");
      }
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      console.log("this.selectedVehicle:",this.selectedVehicle)
      var that = this
      var dataRequest = {
        id:this.model.id,
        listData:this.listData,
        stt:this.order_shipping.stt,
        vehicle_id:this.selectedVehicle,
        staff_id:this.selectedStaffDelivery,
        note:this.order_shipping.note,
      }
      try {
        this.$commonFuction.is_loading(true);
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/process-update-coordinator', dataRequest, {headers});
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        this.$commonFuction.is_loading(false);
        this.$router.push({ path: '/admin/list-order_shipping' ,query: { key_tmp: (new Date()).getTime() } })
        //location.reload()
        return true
      } catch (err) {
        this.$commonFuction.is_loading(false);
        var message = err.response.data.message;
        console.log("messagemessage:",message)
        if (message=='UPDATE_STATUS_ERR'){
          var list_err = err.response.data.data
          alert("Các đơn hàng không thể cập nhật điều phối:" + list_err.join(', '));
        }else if (message=='CODE_USED_ERR'){
          alert("Mã chuyến giao đã tồn tại!");
        }
        else {
          that.$toast.add({
            severity: "error",
            summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
            life: 3000,
          });
        }
        console.log(err);
        return false;
      }
    },
  },
}
</script>

<style lang="scss">

@media (min-width: 1000px) {
  .style-add-order {
    width: 288px !important;
  }
}
  .p-multiselect {
    width: 450px;
  }
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;

  }
  .category-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .2rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-multiselect-label-container {
    height: 40px;
  }

  //style form add-edit
  label.p-col-fixed txt-right {
    margin-bottom: 0px !important;
  }
  div.p-card-field {
    padding: 0px !important;
  }
  .p-col-fixed {
    padding: 0px !important;
    margin: 0px 0px 0px 9px !important;
  }
  .input_text {
    width: 450px !important;
    height: 40px !important;
  }
  .txt-right {
    font-weight: bold;
  }

  .multiselect {
    width: 450px !important;
  }

  .input_width {
    width: 450px;
  }
  .p-formgrid .fixed-bottom-toolbar {
    border-top: none !important;
  }

  .style_add_order_status {
    margin-left: 16px;
    background: #00973D 0% 0% no-repeat padding-box;
    border: 1px solid #00973D;
    border-radius: 3px;
    /*position: absolute;
  right: -50px;
  bottom: 8px;*/
  }
  .style_table_ship {
    .p-datatable {
      padding: 8px;
    }
  }
  //==========
  .div_order {
    display: flex !important;
  }
  .vs__clear {
    display: none !important;
  }
  .p-inputtext {
    padding: 10px !important;
  }

//}

//-------------------------------
@media (max-width: 600px){

  .p-formgrid .fixed-bottom-toolbar .p-button{
    margin-right: 0px!important;
  }
  .p-selectable-row{
    border: none!important;
  }
  .input_order{
    border: 1px solid #D0D4D9 ;
    width: calc(100% - 107px);
  }
  .div_button{
    display: flex!important;
    //width: 36.5%!important;
    //justify-content: space-between!important;

  }
  .div_order{
    display: flex!important;
  }
  .vs__selected-options{
    height: 46px!important;
  }

//  -----------------------------------tabel
  .p-component *{
    text-align: right!important;
  }
  .responsive-label{
    display: block!important;
  }
  .responsive-label {
    font-weight: bold!important;
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
  }
  .responsive-label{
    padding:0px!important;
  }

  .p-column-title{
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;

  }
  .common {
    display: flex !important;
    width: 50% !important;
    justify-content: space-between !important;
  }
  .p-button-edit{
    margin-right: 13px!important;
    width: 38px!important;
    height: 32px!important;
  }
  .p-button-danger{
    //margin: 0px 0px 0px 12px!important;
    text-align: right!important;
    width: 38px!important;
    height: 32px!important;
  }
  .action{
    display: flex!important;
  }
  // tim kiem
  .search{
    display: block!important;
    padding-left: 0px!important;
  }
  .input_search{
    width: 100%!important;
    height: 48px!important;
    border: 1px solid #D0D4D9;
  }
  .text_align{
    text-align: left!important;
    font-size: 16px!important;
    font-weight: bold;
  }
  // button add
  .div_button_add{
    display: flex!important;
    justify-content: end!important;
    margin-top: 12px!important;
  }
  .button_add{
    width: 38px!important;
    height: 38px!important;
    background-color: #00973D;
    margin-left: 12px!important;
  }
  #search{
    padding:12px!important;
    text-align: left!important;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td{
    display: flex !important;
    //align-items: end!important;
    justify-content: space-between!important;
  }


  .vs__clear{
    display: none!important;
  }
  .vs__selected-options{
    height: 46px!important;
  }
  .border_input{
    border: 1px solid #D0D4D9;
  }

  .p-toolbar-group-right{
    display: unset!important;
  }
  .callback-btn-cus{
    display: block!important;
    margin-bottom: 12px!important;
    margin-left: 162px!important;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td{
    padding: 0px!important;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child{
    padding: 11px!important;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child .responsive-label{
    padding: 0px!important;
  }
  .txt-center{
    align-items: start!important;
  }
}

//-----------------------------------
@media (max-width: 960px){
  .p-button{
    margin-right: 0px!important;
  }
  .input_order{
    border: 1px solid #D0D4D9 ;
    //width: 80.5%!important;
    width: calc(100% - 107px);
  }
  .div_button{
    display: flex!important;
    //width: 19.5%!important;
    //justify-content: space-between!important;
  }
  .div_order{
    display: flex!important;
  }

//  ----------------------table
  .p-component *{
    text-align: right!important;
    align-items: center!important;
  }


  .responsive-label {
    font-weight: bold!important;
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
  }
  .responsive-label{
    //padding:12px!important;
  }
  .p-column-title{
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
    justify-content: space-between!important;
    align-items: center!important;
  }
  .common{
    display: flex!important;
    width: 50%!important;
    justify-content: space-between!important;
  }
  .p-button-edit{
    margin-right: 13px!important;
    width: 38px!important;
    height: 32px!important;
  }
  .p-button-danger{
    //margin: 0px 0px 0px 12px!important;
    text-align: right!important;
    width: 38px!important;
    height: 32px!important;
  }
  .action{
    display: flex!important;
  }


  // tim kiem
  .search{
    display: block!important;
    padding-left: 0px!important;
  }
  .input_search{
    width: 100%!important;
    height: 48px!important;
    border: 1px solid #D0D4D9;
  }
  .text_align{
    text-align: left!important;
    font-size: 16px!important;
    font-weight: bold;
  }
  // button add
  .div_button_add{
    display: flex!important;
    justify-content: end!important;
    margin-top: 12px!important;
  }
  .button_add{
    width: 38px!important;
    height: 38px!important;
    background-color: #00973D;
    margin-left: 12px!important;
  }
  #search{
    padding:12px!important;
    text-align: left!important;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td{
    display: flex !important;
    //align-items: end!important;
    justify-content: space-between!important;
  }

  #id_border_action{
    border: 1px solid red !important;
  }
  .p-toolbar-group-right{
    display: unset!important;
  }
  .callback-btn-cus{
    display: block!important;
    margin-bottom: 12px!important;
    margin-left: 162px!important;
  }

  .vs__clear{
    display: none!important;
  }
  .border_input{
    border: 1px solid #D0D4D9;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td{
    padding: 0px!important;
  }
  .p-selectable-row{
    border: none!important;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child{
    padding: 11px!important;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child .responsive-label{
    padding: 0px!important;
  }
  .txt-center{
    align-items: start!important;
  }

}
</style>
