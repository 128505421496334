<template>
    <div class="scan d-inline">
      <!-- <div class="p-inputgroup flex-1">
          <InputText v-model="text" type="text" placeholder="Mã qr/barcode" :readonly="true" @change="scanCode"/>
          <Button label="Quét mã" icon="fa fa-barcode" @click="visible = true" />
      </div> -->
      <img src="/assets/images/icon/ic_scan_mobile.svg" alt="" class="d-lg-none d-inline rounded ml-3 mb-2" @click="visible = true" style="height: 45px !important; width: 45px !important;">
      <Dialog class="scan-dialog" :visible.sync="visible" modal dismissableMask header="Quét mã" :style="{ width: '50vw' }">
          <Scan @result_code="success_scan" :invisible.sync="visible" v-show="visible" :value.sync="text"/>
      </Dialog>
      
      <!-- <Dialog class="scan-dialog" :visible.sync="scanSuccess" modal :closeOnEscape="false" :header="text" :style="{ width: '50vw' }">
          <p>{{message}}</p>
          <template #footer>
              <Button label="Từ chối" icon="pi pi-times" @click="reject()" text />
              <Button label="Đồng ý" icon="pi pi-check" @click="accept()" autofocus />
          </template>
      </Dialog> -->
    </div>
  </template>
  
  <script>
  import Scan from '@/components/Scan';
  export default {
    name: "ScanMenu",
    components: {
      Scan
    },
    data() {
      return {
        visible: false,
        scanSuccess: false,
        text: "",
        message: "",
      }
    },
    methods: {
      async scanCode() {
          this.scanSuccess = true;
          this.message = "Bạn có muốn <hành động> đơn hàng <mã đơn hàng>";
      },
      reject() {
          this.scanSuccess = false;
          this.$toast.add({
              severity: 'error',
              // summary: "Từ chối",
              detail: "Bạn đã từ chối <hành động> đơn hàng <mã đơn hàng>",
              life: 3000
          });
      },
      accept() {
          this.scanSuccess = false
          this.$toast.add({
              severity: 'success',
              // summary: 'Thành công',
              detail: '<Hành động> thành công',
              life: 3000
          })
      },
      success_scan(code) {
        console.log("scan thanh con :",code)
        this.$emit('success_scan', code);
      }
    }
  }
  </script>
  
  <style>
  @media (max-width: 960px) {
      div.p-dialog.p-component.scan-dialog {
          width: 100vw !important;
      }
  }
  img{
    cursor: pointer;
  }
  </style>